import React from 'react'
import { css } from '@emotion/react'

import { CategoryLink } from './link'
import { timeAgo } from '../utils/time-ago'
import { TimeToRead } from './time-to-read'

export function PostHeader({ category, categorySlug, date, timeToRead }) {
  return (
    <header>
      <time
        title={`Zveřejněno ${new Date(date).toLocaleDateString()}`}
        dateTime={date}>
        {timeAgo(date)}
      </time>
      {categorySlug && (
        <>
          <span css={separatorCss} />
          <CategoryLink to={categorySlug}>{category}</CategoryLink>
        </>
      )}
      <span css={separatorCss} />
      <TimeToRead value={timeToRead} />
    </header>
  )
}

const separatorCss = css`
  ::after {
    content: '•';
    padding: 0 0.5rem;
  }
`
