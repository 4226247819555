import React from 'react'

export class TimeToRead extends React.PureComponent {
  getText(value) {
    if (value >= 5) return `${value} minut čtení`
    if (value >= 2) return `${value} minuty čtení`
    if (value >= 1) return `${value} minuta čtení`
    return ''
  }
  render() {
    const { props, getText } = this
    const text = getText(props.value)
    return text && <span>{text}</span>
  }
}
