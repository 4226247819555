import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/layout'
import { Post } from '../components/post'
import { absoluteUrl } from '../utils/url'

const description = "Pomůžu ti s korekturou a stylistikou, proměním tvoje texty v atraktivnější a čtivější. Taky pro tebe napíšu texty, díky kterým zazáříš. Navíc píšu články na blog o tom, co se může hodit." // prettier-ignore

export default function IndexPage({ data, location }) {
  const { posts = [] } = data.allMarkdown || {}
  const { images = [] } = data.allImage || {}
  const imageMap = images.reduce((map, item) => {
    const image = item.node.fluid
    map[image.originalName] = image
    return map
  }, {})
  return (
    <Layout>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={absoluteUrl(location.pathname)} />
      </Helmet>
      {posts.map(({ post }) => (
        <Post
          {...post.frontmatter}
          {...post.fields}
          key={post.id}
          image={imageMap[post.fields.image]}
          timeToRead={post.timeToRead}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdown: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { date: { ne: null } } }
    ) {
      totalCount
      posts: edges {
        post: node {
          id
          frontmatter {
            category
            date
            perex
            title
          }
          fields {
            slug
            categorySlug
            image
          }
          timeToRead
        }
      }
    }
    allImage: allImageSharp {
      totalCount
      images: edges {
        node {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  }
`
