import React from 'react'
import { css } from '@emotion/react'
import Img from 'gatsby-image'

import { Link, ReadLink } from './link'
import { PostHeader } from './post-header'

export function Post(props) {
  const {
    category,
    categorySlug,
    date,
    image,
    content,
    perex,
    full,
    title,
    timeToRead,
    slug,
  } = props
  const readMoreText = `Přečíst více o článku: ${title}`
  const imgAltText = `Obrázek ke článku: ${title}`
  const anchor = 'pokracovat-ve-cteni'
  return (
    <article css={articleCss}>
      <PostHeader
        date={date}
        category={category}
        categorySlug={categorySlug}
        timeToRead={timeToRead}
      />
      {full ? (
        <>
          <h1>{title}</h1>
          {image && <Img fluid={image} alt={imgAltText} />}
          <p>{perex}</p>
          <hr id={anchor} />
          {content}
        </>
      ) : (
        <>
          <h1>
            <Link to={slug} title={readMoreText}>
              {title}
            </Link>
          </h1>
          {image && (
            <Link to={slug} title={readMoreText}>
              <Img fluid={image} alt={imgAltText} />
            </Link>
          )}
          <p>{perex}</p>
          <ReadLink to={`${slug}#${anchor}`} title={readMoreText}>
            Přečíst
          </ReadLink>
        </>
      )}
    </article>
  )
}

const articleCss = css`
  margin-bottom: 4rem;
  header {
    font-size: 0.75rem;
    color: #333;
    text-transform: uppercase;
    margin-bottom: -2rem;
  }
`
